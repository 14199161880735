import styled from "styled-components";
import media, { screens } from "utilities/styles/media";

// type AboutCSSType = {
// 	order?: string;
// };

export const AboutContainer = styled.section`
	width: ${screens.standard};
	max-width: ${screens.wide};
	margin: auto;
	margin-block: 100px;
	margin-top: 20px;
	margin-bottom:0;

	${media.tablet`
    width: screens.wide; 
    margin: auto;
  `}
`;

export const AboutMain = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-block: 50px;

	${media.tablet`
    flex-direction: column-reverse;
		gap: 30px;
    width: screens.wide;
    margin: auto;
  
  `}
`;
