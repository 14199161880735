import { useEffect, useState } from "react";
import {
	DescriptionArea,
	LogoArea,
	MenuArea,
	MenuAreaInner,
	MenuHeaderContainer,
	MenuHeaderWrapper,
	MenuOverlay,
} from "./style";
import TopNavigation, { scrollToId } from "./navigation/TopNavigation";
import { Hamburger } from "./navigation/sideMenu/style";
// import BrandColors from "utilities/styles/colors";
import { HAMBURGER, HOME_HEADER, LOGO } from "assets";
import SideMenu from "./navigation/sideMenu";
import Typist from "react-typist";
import { HOME_URL } from "routes/frontend";

export default function MenuHeader() {
	const [sideMenu, setSideMenu] = useState(false);
	const [scrolling, setScrolling] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100) {
				// Change background color when user scrolls down
				setScrolling(true);
			} else {
				// Reset background color when user scrolls back to the top
				setScrolling(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const backgroundColor = scrolling ? "black" : "transparent";
	const color = scrolling ? "white" : "white";

	return (
		<MenuOverlay>
			<Hamburger onClick={() => setSideMenu(true)} src={HAMBURGER} alt="hamburger" />
			<MenuHeaderContainer img={HOME_HEADER}>
				<SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
				<MenuHeaderWrapper style={{ backgroundColor }} />
				<MenuArea style={{ backgroundColor }}>
					<MenuAreaInner>
						<TopNavigation textColor={color} />
						<LogoArea onClick={() => scrollToId(HOME_URL)} src={LOGO} alt="logo" />
					</MenuAreaInner>
				</MenuArea>
				<DescriptionArea>
					<Typist avgTypingDelay={100}>
						<h3>
							<span style={{ fontSize: 25 }}>Welcome to</span> <br />
							{/* <Typist.Backspace count={12} delay={200} />{" "} */}
							<span style={{ color: "#CE9A10" }}>GreenVille</span>{" "}
							<span style={{ color: "#0FA816" }}>Media</span>
						</h3>
					</Typist>
					<Typist>
						<p>Dynamic End-To-End Media Production and Services </p>
					</Typist>
				</DescriptionArea>
			</MenuHeaderContainer>
		</MenuOverlay>
	);
}

// <Hamburger
// 	onClick={() => setSideMenu(true)}
// 	src={HAMBURGER}
// 	alt="hamburger"
// />
// {/* <DescriptionArea>
// 	<h3>{title}</h3>
// 	<p>{description}</p>
// </DescriptionArea> */}
// <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
// {/* <MenuArea style={{ backgroundColor }}>
// 	<Link style={{ position: "fixed", top: 10 }} to={HOME_URL}>
// 		<LogoArea src={LOGO} alt="logo" />
// 	</Link>
// 	<TopNavigation textColor={color} />
// </MenuArea> */}
