import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { ModalContainer, ModalContentHolder, ModalHeader } from "./style";
import { createPortal } from "react-dom";
import { MENU_CANCEL } from "assets";

type ModalType = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
};
const Modal = ({ children, setShowModal }: PropsWithChildren<ModalType>) => {
	const close = () => setShowModal(false);
	return (
		<>
			{createPortal(
				<ModalContainer>
					<ModalContentHolder>
						<ModalHeader>
							<span>
								<img onClick={close} src={MENU_CANCEL} alt="cancelIcon" />
							</span>
						</ModalHeader>
						{/* {createPortal(children, document.body)} */}
						{children}
					</ModalContentHolder>
				</ModalContainer>,
				document.body
			)}
		</>
	);
};

export default Modal;
