import styled from "styled-components";
import media, { screens } from "utilities/styles/media";

type ServicesCssType = {
	order?: string;
};

export const ServicesContainer = styled.div`
	padding-block: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: ${screens.wide};
	max-width: ${screens.standard};
	margin-inline: auto;
	padding-bottom: 0;
	padding-top: 0;

	${media.tablet`
  width: 100vw;
  padding: 24px;
	`}
`;

export const ServicesText = styled.p`
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	width: 800px;
	text-align: center;

	${media.tablet`
    width:90%;
    font-size: 16px;
    margin-top: 0px;
	`}
`;

export const ServicesHeadText = styled.h1`
	color: #ce9a10;
	text-align: center;
	font-family: Crimson Text;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-block: 50px;
	margin-bottom: 25px;

	${media.tablet`
    font-size: 32px;
    margin-top: 30px;
	`}
`;

export const ServiceMain = styled.div<ServicesCssType>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin: 80px 0 40px 0;

	img {
		width: 45%;
		border-radius: 8px;
		order: ${({ order }) => (order === "switch" ? 1 : null)};
		${media.tablet`
    width: 100%;
    order: 0;
	`}
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 45%;
		order: ${({ order }) => (order === "switch" ? 0 : null)};

		${media.tablet`
      width: 100%;
      order: 1;
	`}
	}

	h1 {
		color: #0fa816;
		font-family: Crimson Text;
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		width: 200px;
		margin-bottom: 30px;

		${media.tablet`
      font-size: 28px;
      margin-top: 40px;
	`}
	}

	h5 {
		color: #000;
		font-family: Crimson Text;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		${media.tablet`
      font-size: 16px;
      margin-top: -10px;
	`}
	}

	${media.tablet`
    flex-direction: column;
    margin: 40px 0 40px 0;
	`}
`;
