import styled from "styled-components";
import BrandColors from "utilities/styles/colors";
import media from "utilities/styles/media";
import { screens } from "utilities/styles/media";

export const FooterContainer = styled.footer`
	background-color: black;
	color: ${BrandColors.white};
	padding-block: 50px;
`;

export const FooterMain = styled.div`
	width: ${screens.wide};
	max-width: ${screens.standard};
	margin: auto;
`;

export const FooterInner = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 30px;

	${media.tablet`
    flex-direction: column;
    .logo {
      width: 50%;
    }
  `}
`;

export const FooterContact = styled.div``;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-block: 20px;

	* {
		text-decoration: none;
		color: ${BrandColors.white};
	}
`;

export const List = styled.ul`
	list-style-type: none;
`;

export const CopyrightContainer = styled.p`
	text-align: center;
	margin-block: 30px;
`;

export const SocialsHolder = styled.div`
	display: flex;
	gap: 12px;
	margin-block: 16px;
`;
