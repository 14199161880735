import styled from "styled-components";
import media, { screens } from "utilities/styles/media";

export const ValuesContainer = styled.div`
	padding: 0px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: ${screens.wide};
	max-width: ${screens.standard};
	margin-inline: auto;
	margin-bottom: 50px;
	gap: 50px;

	${media.tablet`
	width: {screens.standard};
	margin-inline: auto;
	margin-top: 0px;
	margin-bottom: 0px;
	`}
`;

export const ValueHeadText = styled.h1`
	color: #ce9a10;
	text-align: center;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 20px;

	${media.tablet`
    font-size: 32px;
    margin-top: 0px;
		margin-bottom: -70px;
	`}
`;

export const ValueFlex = styled.div`
	display: flex;
	/* padding: 40px 100px; */
	gap: 50px;
	justify-content: space-between;
	width: 100%;
	/* margin-top: 80px; */

	div {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 32px 24px 64px 24px;
		border-radius: 16px;
		background: #fff;
		box-shadow: 0px 20px 24px -4px rgba(0, 0, 0, 0.08),
			0px 8px 8px -4px rgba(0, 0, 0, 0.03);

		h2 {
			color: #0fa816;
			text-align: center;
			font-family: Crimson Text;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-top: 24px;
			margin-bottom: 16px;

			${media.tablet`
        font-size: 24px;
      `}
		}

		img {
			width: 162px;

			${media.tablet`
        width: 162px;
      `}
		}

		${media.tablet`
      width: 100%;
      margin-top: 18px;
      text-align: center;
	`}
	}

	${media.tablet`
  flex-direction: column;
  width: 100vw;
  padding: 24px;
  margin-top: 10px;
	`}
`;
