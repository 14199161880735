import React, { useEffect, useRef, ReactNode } from "react";
import gsap from "gsap";

interface RandomIntroComponentProps {
	children: ReactNode;
}

const aniStyle = {
	marginTop: 0,
	marginBottom: 0,
	border: "none",
	boxShadow: "none",
};

const RandomAnimation: React.FC<RandomIntroComponentProps> = ({ children }) => {
	const componentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const element: any = componentRef.current;

		// Function to handle the intersection observer callback
		const handleIntersect: IntersectionObserverCallback = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const xValues = [-3000, 3000];
					// Animation to play when the component is in view
					gsap.from(element, {
						x: xValues[Math.floor(Math.random() * xValues.length)],
						opacity: 1,
						duration: 0.5,
					});

					// Unobserve the component after it's been animated
					observer.unobserve(element);
				}
			});
		};

		// Create an Intersection Observer
		const observer = new IntersectionObserver(handleIntersect, {
			threshold: 0.1, // Adjust the threshold as needed
		});

		if (element) {
			// Observe the component
			observer.observe(element);
		}

		// Cleanup the observer when the component is unmounted
		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<div style={aniStyle} ref={componentRef}>
			{children}
		</div>
	);
};

export default RandomAnimation;
