import MenuHeader from "components/menuHeader";
import Services from "components/services";
import { HomeContainer } from "./style";
import Values from "components/values";
import About from "components/about";
import PastEvents from "components/pastEvents";
import Brands from "components/brands";
import Footer from "components/footer";
import { HOME_URL } from "routes/frontend";

export default function HomePage() {
	return (
		<HomeContainer id={HOME_URL}>
			<MenuHeader />
			<About />
			<Services />
			<Values />
			{/* <MissionAndVision /> */}
			<PastEvents />
			<Brands />
			<Footer />
		</HomeContainer>
	);
}
