import styled from "styled-components";
import media from "utilities/styles/media";
import { screens } from "utilities/styles/media";

export const PastEventsContainer = styled.section`
	width: ${screens.wide};
	max-width: ${screens.standard};
	margin: auto;
	margin-block: 40px;
	margin-top: -40px;

	${media.tablet`
    width: screens.standard;
    margin: auto;
  `}
`;

export const PastEventsMain = styled.div`
	width: 100%;
`;

export const GridItem = styled.div`
	overflow: hidden;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

export const GridContainer = styled.div`
	display: grid;
	gap: 20px;
	grid-auto-rows: 150px;
	grid-template-areas:
		"one one one three three"
		"one one one three three"
		"one one one four four"
		"two two two four four"
		"two two two five five"
		"two two two five five";

	.video1 {
		background-color: red;
		grid-area: one;
	}
	.video2 {
		grid-area: two;
	}

	.image1 {
		grid-area: three;
	}
	.image2 {
		grid-area: four;
	}
	.image3 {
		grid-area: five;
	}

	${media.tablet`
  grid-auto-rows: 250px;
  grid-template-areas:
		"one"
    "two"
    "three"
    "four"
    "five";
  `}
`;

export const FloatingPlay = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5;
	top: 0;
	left: 0;
	cursor: pointer;

	img {
		width: 14%;
		object-fit: contain;
	}

	${media.tablet`

  `}
`;
