import styled from "styled-components";
import BrandColors from "utilities/styles/colors";
import media from "utilities/styles/media";

export const ModalContainer = styled.section`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100svh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 100;
	${media.tablet`

  `}
`;

export const ModalContentHolder = styled.div`
	position: relative;
	width: 60%;
	height: 70%;
	background-color: white;
	margin: auto;

	iframe {
		z-index: 100;
		border: none;
	}

	${media.tablet`
    width: 90%;
    height: 200px;
  `}
`;

export const ModalHeader = styled.div`
	position: relative;
	top: 0;
	left: 0;
	/* border: 1px solid red; */
	height: 0px;
	width: 100%;
	display: flex;
	padding-inline: 0px;
	align-items: center;
	justify-content: flex-end;
	z-index: 103;

	${media.tablet`
      justify-content: center;
      top: unset;
      bottom: -160%;
      left: 0;
  `}

	span {
		background-color: ${BrandColors.primary};
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		position: absolute;
		top: -25px;
		right: -25px;
		cursor: pointer;

		${media.tablet`
        top: unset;
        bottom: -200%;
        right: 40%;
    `}
	}
`;
