import React from "react";
import { Container, Logo, SideItems, Item, Paragraph, MenuFlex } from "./style";
// import whiteForward from "assets/images/svgs/forwardWhite.svg";
import { navbarData } from "../data";
import {  MENU_CANCEL } from "assets";
import { scrollToId } from "../TopNavigation";

const SideMenu = ({ sideMenu = false, setSideMenu }: any) => {
	// const auth = new AuthenticationClass();
	// const { sideMenu, SetSideMenu } = useSideMenuContext();
	// const prodRoutes = sideMenuData.filter((route) => !route.hide);
	function closeSideMenu() {
		setSideMenu(false);
	}

	function handleClick(id: string) {
		scrollToId(id);
		closeSideMenu();
	}
	return (
		<Container open={sideMenu}>
			<Logo>
				<div>
					{/* <img style={{ width: 150, height: 50 }} src={LOGO} alt="" /> */}
				</div>
				<h1 onClick={() => closeSideMenu()}>
					<img src={MENU_CANCEL} alt="cancelIcon" />
				</h1>
			</Logo>
			<SideItems>
				{navbarData.map((menuItem, i) => (
					<MenuFlex key={i} onClick={() => handleClick(menuItem.path)}>
						<Item>
							{/* <img src={menuItem.icon} alt="" /> */}
							<Paragraph>{menuItem.name}</Paragraph>
						</Item>
						{/* <img src={whiteForward} alt="" /> */}
					</MenuFlex>
				))}
			</SideItems>
			{/* <LogoutButton onClick={() => auth.handleLogout()}>Log Out</LogoutButton> */}
		</Container>
	);
};

export default SideMenu;
