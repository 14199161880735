import React from "react";
import { ValueFlex, ValueHeadText, ValuesContainer } from "./style";
import { VALUES1, VALUES2, VALUES3, VALUES4 } from "assets";
import { VALUES_URL } from "routes/frontend";

const valuesData = [
	{
		title: "Creativity and Innovation",
		description: `We constantly strive to think outside the box, challenge conventions, and deliver fresh and exciting concepts
    that captivate and engage audiences`,
		image: VALUES1,
		order: "null",
	},
	{
		title: "Professionalism and Excellence",
		description: `We maintain the highest standards of professionalism, integrity, and ethical conduct in all our endeavours. 
    We are committed to delivering excellence in every aspect of our work, from pre production planning to post-delivery evaluation. 
    We ensure meticulous attention to detail, seamless execution, and a focus on exceeding expectations`,
		image: VALUES2,
		order: "switch",
	},
	{
		title: "Collaboration and Relationships",
		description: `We value collaboration and build strong relationships with our clients, partners, and vendors. We believe in 
    fostering open and transparent communication, listening to our clients' needs, and working closely together to achieve their 
    objectives.`,
		image: VALUES3,
		order: "null",
	},
	{
		title: "Flexibility and Adaptability",
		description: `We understand that every client and project is unique, and we pride ourselves on our ability to adapt to different
		 requirements and challenges. We approach each project with flexibility, tailoring our services to suit specific needs and preferences.`,
		image: VALUES4,
		order: "switch",
	},
];

export default function Values() {
	return (
		<ValuesContainer id={VALUES_URL}>
			<ValueHeadText>Values</ValueHeadText>
			<ValueFlex>
				<div>
					<img src={valuesData[0].image} alt="val" />
					<h2>{valuesData[0].title}</h2>
					<p>{valuesData[0].description}</p>
				</div>

				<div>
					<img src={valuesData[1].image} alt="val" />
					<h2>{valuesData[1].title}</h2>
					<p>{valuesData[1].description}</p>
				</div>
			</ValueFlex>
			<ValueFlex>
				<div>
					<img src={valuesData[2].image} alt="val" />
					<h2>{valuesData[2].title}</h2>
					<p>{valuesData[2].description}</p>
				</div>
				<div>
					<img src={valuesData[3].image} alt="val" />
					<h2>{valuesData[3].title}</h2>
					<p>{valuesData[3].description}</p>
				</div>
			</ValueFlex>
		</ValuesContainer>
	);
}
