import styled from "styled-components";
import media from "utilities/styles/media";

export const HomeContainer = styled.div`
	/* width: 100vw; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
	${media.tablet`
	`}
`;
