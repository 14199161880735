import { AboutContainer, AboutMain } from "./style";
import {
	CaptionHolder,
	ImageHolder,
	SubHeading,
	Text,
} from "utilities/styles/globalStyles";
import { ABOUT_IMAGE } from "assets";
import { ABOUT_US_URL } from "routes/frontend";
import RandomAnimation from "components/animatedDiv/RandomAnimation";

const data = [
	{
		id: "1",
		heading: "Innovative Entertainment, Exceptional Experiences",
		text: "Greenville Media is an innovative entertainment firm that specializes in end-to-end media production, advert and media buying, event services and media supplies. As a young and dynamic company, we are driven by a passion for creativity and a commitment to delivering exceptional experiences through high-quality content and innovative media solutions. We offer a comprehensive range of services to clients; from television/film production, full media content production, digital media solutions, advert and media buying, talent sourcing, event services and supplies of media equipment and accessories. We combine the art of storytelling with the strategic execution of events to create impactful and memorable moments both on screen and off screen. Our mission at Greenville Media is to entertain, engage, and inform audiences through exceptional content and media experiences. We strive to provide our clients with exceptional service, outstanding creativity, and seamless execution, ensuring that each project we undertake is a resounding success.",
		image: ABOUT_IMAGE,
	},
];

const About = () => {
	return (
		<RandomAnimation>
			<AboutContainer id={ABOUT_US_URL}>
				{data.map((item) => (
					<AboutMain key={item.id}>
						<CaptionHolder w="50%" order={1}>
							<SubHeading>{item.heading}</SubHeading>
							<Text>{item.text}</Text>
						</CaptionHolder>

						<ImageHolder order={0}>
							<img src={item.image} alt="About" width="100%" />
						</ImageHolder>
					</AboutMain>
				))}
			</AboutContainer>
		</RandomAnimation>
	);
};

export default About;
