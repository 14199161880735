import React from "react";
import { BrandsContainer, BrandsMain } from "./style";
import { CONSUMATE, KWAKOL, SUZUKI } from "assets";

const data = [
	{
		id: 1,
		src: CONSUMATE,
		alt: "consumate logo",
	},

	{
		id: 2,
		src: KWAKOL,
		alt: "kwakol logo",
	},

	{
		id: 3,
		src: SUZUKI,
		alt: "suzuki logo",
	},
];

const Brands = () => {
	return (
		<BrandsContainer>
			<BrandsMain>
				{data.map((item) => (
					<img src={item.src} alt={item.alt} key={item.id} />
				))}
			</BrandsMain>
		</BrandsContainer>
	);
};

export default Brands;
