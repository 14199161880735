//frontend urls
export const base_url = window.location.origin;

//Home Routes
export const HOME_URL = "#home";
export const SERVICES_URL = "#services";
export const VALUES_URL = "#values";
export const ABOUT_US_URL = "#aboutUs";
export const MISSION_AND_VISION_URL = "#mission";
export const PAST_EVENTS_URL = "#pastEvents";
export const BRANDS_URL = "#brands";
//we use this for tracking dont change it
