import {
	ABOUT_US_URL,
	HOME_URL,
	PAST_EVENTS_URL,
	SERVICES_URL,
	VALUES_URL,
} from "routes/frontend";

export const navbarData = [
	{
		id: 1,
		name: "Home",
		path: HOME_URL,
		sub: HOME_URL,
	},
	{
		id: 3,
		name: "About Us",
		path: ABOUT_US_URL,
	},
	{
		id: 2,
		name: "Services",
		path: SERVICES_URL,
	},
	{
		id: 4,
		name: "Values",
		path: VALUES_URL,
	},
	{
		id: 5,
		name: "Past Events",
		path: PAST_EVENTS_URL,
	}
];
