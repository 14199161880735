import { css } from "styled-components";

type RuleOrQueryType = TemplateStringsArray;

const mediaQuery = (query: RuleOrQueryType) => (rules: RuleOrQueryType) =>
	css`
		@media screen and (${css(query)}) {
			${css(rules)}
		}
	`;

const media = {
	smallMobile: mediaQuery`max-width: 320px`, // iphone 5/SE 😂
	mobile: mediaQuery`max-width: 480px`, // mobile
	tablet: mediaQuery`max-width: 768px`, // tablets
	smallDesktop: mediaQuery`min-width: 1024px`, // tablets landscape, small desktops
	smallDesktopMinimum: mediaQuery`max-width: 1024px`, // tablets landscape, small desktops
	print: mediaQuery`print`,
};

export default media;
export const screens = {
	wide: "90%",
	standard: "1200px",
};
