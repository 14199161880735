import { navbarData } from "./data";
import { BottomCenterWrapper } from "./style";

export function scrollToId(id: any) {
	const element = document.getElementById(id);

	if (element) {
		// Calculate the offset position of the element
		const offset = element.getBoundingClientRect().top + window.scrollY - 100;
		console.log({ offset });
		// Use the `scrollTo` method with behavior: 'smooth'
		window.scrollTo({
			top: offset,
			behavior: "smooth",
		});
	}
}
export default function TopNavigation({ textColor }: any) {
	return (
		<BottomCenterWrapper>
			{navbarData.map((menu, i) => (
				<h3
					key={i}
					onClick={() => scrollToId(menu.path)}
					style={{ textDecoration: "none", color: textColor }}
				>
					{menu.name}
				</h3>
			))}
		</BottomCenterWrapper>
	);
}
