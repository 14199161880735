import styled from "styled-components";
import media, { screens } from "utilities/styles/media";

type PrivateStyleProps = {
	img?: string;
};

export const MenuHeaderContainer = styled.div<PrivateStyleProps>`
	width: 100%;
	height: 95vh;
	background-image: url(${(props) => props.img});
	background-size: cover;
	background-position: center;
	padding: 16px 64px;

	${media.tablet`
    padding: 16px;
		background-position: right;
	`}
`;

export const MenuHeaderWrapper = styled.div`
	display: none;
	${media.tablet`
		display: block;
		width: 100%;
		padding: 16px;
		height: 90px;
		position: fixed;
		top: 0;
		left: 0;
    padding: 16px;
		/* background-color: red !important; */
		z-index: 10;
	`}
`;

export const MenuOverlay = styled.div`
	position: relative;
	overflow: hidden;
	z-index: 20;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
		opacity: 1;
		transition: opacity 0.3s ease;
	}

	${media.tablet`
		
	`}
`;

export const MenuArea = styled.div`
	display: flex;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100px;
	z-index: 10;
	top: 0;
	left: 0;
	${media.tablet`
		height: 50px;
		padding: 20px 0;
	`}
`;

export const MenuAreaInner = styled.div`
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	width: ${screens.wide};
	max-width: ${screens.standard};
	margin-inline: auto;

	${media.tablet`
		flex-direction: row;
	`}
`;

export const LogoArea = styled.img`
	width: 240px;

	${media.tablet`
		width: 100px;
		margin-top: 20px;
	`}
`;

export const JoinTripButton = styled.button`
	background: var(--Primary-Color-1, #f7a49d);
	color: white;
	border: none;
	border-radius: 8px;
	padding: 8px 16px;
	cursor: pointer;
	margin-top: 10px;
	${media.tablet`
	
	`}
`;

export const DescriptionArea = styled.div`
	width: 100%;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 40%;
	left: 0;
	z-index: 5;

	.typing-text:nth-child(2n-1) {
		color: red;
	}

	.typing-text:nth-child(2n) {
		color: green;
	}

	h3 {
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		width: 544px;
		text-align: center;
		color: white;
	}

	p {
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: white;
		margin-top: 0px;
	}

	${media.tablet`
		width: 100%;
		top: 40%;
		left: 0;
		bottom: 41px;
		padding: 24px 16px;

		h3 {
			color: var(--white, #fff);
			font-size: 32px;
			font-style: normal;
			font-weight: 700;
			width: 100%;
		}

		p {
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			color: white;
			margin-top: 0px;
		}
	`}
`;
