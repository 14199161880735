import styled from "styled-components";
import media from "utilities/styles/media";
import { screens } from "utilities/styles/media";

export const BrandsContainer = styled.section`
	width: ${screens.wide};
	max-width: ${screens.standard};
	margin: auto;

	${media.tablet`
    width: screens.standard;
    margin: auto;
  `}
`;

export const BrandsMain = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-block: 50px;

	img{
		width: 250px;
	}

	${media.tablet`
    img{
      width: 20%;
      margin-block: 20px;
    }
  `}
`;
