import React, { ReactNode } from "react";
import {
	CopyrightContainer,
	FooterContact,
	FooterContainer,
	FooterInner,
	FooterMain,
	List,
	ListItem,
	SocialsHolder,
} from "./style";
import { EMAIL, FACEBOOK, INSTAGRAM, LOGO, PHONE } from "assets";
import { Link } from "react-router-dom";

type FooterContactType = {
	id: number;
	icon: string;
	label: ReactNode;
	alt: string;
};
const data = [
	{
		id: 1,
		icon: EMAIL,
		label: (
			<a href="mailto:info@greenvillemediapro.com">info@greenvillemediapro.com</a>
		),
		alt: "email icon",
	},
	{
		id: 2,
		icon: EMAIL,
		label: (
			<a
				href="mailto:greenvillemediapro@gmail.com
		"
			>
				greenvillemediapro@gmail.com
			</a>
		),
		alt: "email icon",
	},
	{
		id: 3,
		icon: PHONE,
		label: <a href={`tel:+234 803 131 3357`}>+234 803 131 3357</a>,
		alt: "phone icon",
	},
	// {
	// 	id: 1,
	// 	icon: ADDRESS,
	// 	label: <address>No. 12 Macauley Avenue, Ikoyi Lagos State, Nigeria.</address>,
	// 	alt: "address icon",
	// },
];

const Socials = [
	{
		id: 1,
		name: "facebook",
		image: FACEBOOK,
		link: "https://www.facebook.com/profile.php?id=61552019823445&mibextid=LQQJ4d",
	},
	{
		id: 2,
		name: "instagram",
		image: INSTAGRAM,
		link: "https://instagram.com/greenville_media?igshid=MzRlODBiNWFlZA==",
	},
];

const year = new Date().getFullYear();

const Footer = () => {
	return (
		<FooterContainer>
			<FooterMain>
				<FooterInner>
					<img src={LOGO} alt="brand logo" className="logo" />
					<FooterContact>
						<h3>Contact Us</h3>
						<List>
							{data.map((item) => (
								<AddressItem key={item.id} {...item} />
							))}
						</List>
						<br />
						<h3>Social Media</h3>
						<SocialsHolder>
							{Socials.map((item) => (
								<Link key={item.id} to={item.link} target="_blank">
									<img src={item.image} alt={item.name} />
								</Link>
							))}
						</SocialsHolder>
					</FooterContact>
				</FooterInner>
				<Copyright />
			</FooterMain>
		</FooterContainer>
	);
};

export default Footer;

const AddressItem = ({ label, icon, alt }: FooterContactType) => {
	return (
		<ListItem>
			<img src={icon} alt={alt} /> {label}
		</ListItem>
	);
};

const Copyright = () => {
	return (
		<CopyrightContainer>
			Copyright &copy; {year} GreenVille Media{" "}
		</CopyrightContainer>
	);
};
