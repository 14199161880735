import {
	GridItem,
	PastEventsContainer,
	PastEventsMain,
	GridContainer,
	FloatingPlay,
} from "./style";
import { ServicesHeadText } from "components/services/style";
import { EVENT1, EVENT2, EVENT3, PLAY } from "assets";
import { PAST_EVENTS_URL } from "routes/frontend";
import Modal from "components/modal";
import { useState } from "react";
import RandomAnimation from "components/animatedDiv/RandomAnimation";

const videosData = [
	{
		id: 1,
		image: EVENT1,
		alt: "service1",
		youtubeVideoSrc: "https://www.youtube.com/embed/i3kZy2aT1lQ",
	},
	{
		id: 2,
		image: EVENT2,
		alt: "service2",
		youtubeVideoSrc: "https://www.youtube.com/embed/_dZHnf42pgA",
	},
];
const PastEvents = () => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [currVideo, setCurrVideo] = useState("");

	const handleVideoClick = (videoLink: string) => {
		setShowModal(true);
		setCurrVideo(videoLink);
	};
	return (
		<>
			{showModal && (
				<Modal setShowModal={setShowModal}>
					<IFrame src={currVideo} />
				</Modal>
			)}
			<PastEventsContainer id={PAST_EVENTS_URL}>
				<ServicesHeadText>Past Events</ServicesHeadText>
				<PastEventsMain>
					<RandomAnimation>
						<GridContainer>
							{videosData.map((video) => (
								<GridItem className={`video${video.id}`} key={video.id}>
									<img src={video.image} alt={video.alt} />
									<FloatingPlay
										onClick={() => handleVideoClick(video.youtubeVideoSrc)}
									>
										<img src={PLAY} alt="play icon" />
									</FloatingPlay>
								</GridItem>
							))}
							<GridItem className="image1">
								<img src={EVENT1} alt="service 3" />
							</GridItem>
							<GridItem className="image2">
								<img src={EVENT2} alt="service 3" />
							</GridItem>
							<GridItem className="image3">
								<img src={EVENT3} alt="service 3" />
							</GridItem>
						</GridContainer>
					</RandomAnimation>
				</PastEventsMain>
			</PastEventsContainer>
		</>
	);
};

export default PastEvents;

interface IframeHTMLAttribute {
	src?: string;
	width?: number | string;
}
const IFrame = ({ src }: IframeHTMLAttribute) => {
	return (
		<iframe
			style={{ position: "relative", display: "block" }}
			width="100%"
			height="100%"
			src={src}
			title="YouTube video player"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			allowFullScreen
		></iframe>
	);
};
