// this document hold global styles that are shared amongs components in the entire projects
// these styles include but are not limited to heading, subheadings, image layouts, text areas and more
import styled from "styled-components";
import media from "utilities/styles/media";
import BrandColors from "./colors";

// sub-heading styled component
export const SubHeading = styled.h3`
	color: ${BrandColors.primary};
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	width: auto;
	margin-block: 20px;
	margin-inline: auto;
	text-align: left;

	${media.tablet`
    width:90%;
		text-align: center;
    font-size: 24px;
    margin-top: -30px;
	`}
`;

// text area
export const Text = styled.p`
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: left;

	${media.tablet`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
		text-align: center;
  `}
`;

type CaptionHolderCSSType = {
	w?: string; // w represents the width
	order?: number | string; // this represents the flex order
};
export const CaptionHolder = styled.div<CaptionHolderCSSType>`
	height: auto;
	text-align: center;
	width: ${({ w }) => (w ? w : "auto")};

	${media.tablet`
		width: 100%;
		order: ({order}) => order ? order : 0;
	`}
`;

export const ImageHolder = styled.div<CaptionHolderCSSType>`
	width: auto;

	${media.tablet`
		order: ({order}) => order ? order : 0;
		overflow: hidden;
		img {
			width: 165%;
		}
	`}
`;
