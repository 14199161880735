import React from "react";
import { SERVICES_URL } from "routes/frontend";
import {
	ServiceMain,
	ServicesContainer,
	ServicesHeadText,
	ServicesText,
} from "./style";
import { SERVICE1, SERVICE2NEW, SERVICE3NEW, SERVICE4, SERVICE5, SERVICE6 } from "assets";
import RandomAnimation from "components/animatedDiv/RandomAnimation";

const servicesData = [
	{
		title: "Media/Digital Production",
		description: `We offer creative content design and full production services to support our clients and their brands. 
		We excel in producing engaging and high-quality media content across various platforms. From corporate videos and commercials 
		to documentaries, short films, films, TV, web series and any other personalized content recommendations. We handle every aspect
		of the production process. This includes content development, scriptwriting, talent sourcing, filming and post-production. 
		We use high quality equipment and techniques to deliver visually stunning and compelling content that resonates with audiences.`,
		image: SERVICE1,
		order: "null",
	},
	{
		title: "Advert and Media Buying",
		description: `We provide digital media buying services and programs. This includes research, design, and campaign optimization 
		across all of the primary digital media channels and networks. Leveraging on our experience and relationships, we create a tailored 
		plan for brand visibility by helping brands place ads where the best customers see them.`,
		image: SERVICE5,
		order: "switch",
	},
	{
		title: "Event Services",
		description: `We specialize in media coverage of events using high quality media equipment in capturing your most memorable moments. 
		Whether it's corporate conferences, product launches, gala dinners, music festivals, or experiential marketing activations, you can 
		trust us to deliver beyond your expectation.`,
		image: SERVICE3NEW,
		order: "null",
	},
	{
		title: "Radio/Sound Production",
		description: `We provide captivating audio content across various genres, including music, news, talk shows, and podcasts.`,
		image: SERVICE2NEW,
		order: "switch",
	},
	{
		title: "Media/Digital Equipment",
		description: `We offer a variety of media supplies that are essential for media production. This includes cutting-edge media equipment,
		 digital accessories and other consumables.`,
		image: SERVICE4,
		order: "null",
	},
	{
		title: "Brand Ideation",
		description: `We create outstanding and original concepts that help brands to connect with their customers’ emotion and evoke brand loyalty.
		With our team of talented think-tank, and a robust creative design process, we help our clients to drive sales and connect with their customers
		through innovative marketing concepts and strategies.`,
		image: SERVICE6,
		order: "switch",
	},
];

export default function Services() {
	return (
		<ServicesContainer id={SERVICES_URL}>
			<ServicesHeadText>Services</ServicesHeadText>
			<ServicesText>
				Greenville Media brings a fresh perspective to media production. With a
				commitment to creativity, collaboration, and excellence; we strive to deliver
				unforgettable results.
			</ServicesText>
			{servicesData.map((service, i) => (
				<RandomAnimation key={i}>
					<ServiceMain order={service.order}>
						<img src={service.image} alt="imag" />
						<div>
							<h1>{service.title}</h1>
							<h5>{service.description}</h5>
						</div>
					</ServiceMain>
				</RandomAnimation>
			))}
		</ServicesContainer>
	);
}
